/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';

export default class CooperationGirlsPage extends Vue {
	@State('customer') customer: CustomerState | undefined;


	toCreateModelAccount() {
		this.$router.push({ name: 'model-account-registration' }).then(r => r);
	}
}
